export const API = `${process.env.REACT_APP_API_HOST}/api`;



export const Settings = {
  Tenant: {
    Key: process.env.REACT_APP_TENANT,
    IsUlead: process.env.REACT_APP_TENANT === "ULEAD",
    Is34EU: process.env.REACT_APP_TENANT === "34EU",
  },

  TSPFirstStepsInPhase2: true,
  ConsultationMode: true,
  EmployerTypeForAnonymous: 17, // other
  EmployerLevelForAnonymous: 7, // municipality
  EmergencyConsultationId: 10,
  EmergencySupportId: 11,

  // js Month is Zero-based: this is "1st September 2022"
  HideEmergencyConsultationsAfter: new Date(2022, 8, 1),
  HideEmergencySupportAfter: new Date(2022, 8, 16),

  // js Month is Zero-based: this is "31st December 2023"
  HideTSPsAfter: new Date(2022, 11, 31),
};

export const Breakpoints = {
  xs: 0,
  s: 600,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
  minWidth: "320px"
};

const small = ["sm", "xs"];
export  const isSmall = (width) =>(small.includes(width))

const notXl = ["sm", "xs", "md", "lg"];
export  const isNotXl = (width) =>(notXl.includes(width))
export  const isXl = (width) =>(!notXl.includes(width))


export const Endpoints = {
  IndicatorRegions: {
    path: "/measurements/regions",
  },
  Category: {
    path: "/categories",
  },
  Event: {
    path: "/ul-events",
  },
  Trainer: {
    path: "/trainers",
  },
  Facilitator: {
    path: "/facilitators",
  },
  FundingSource: {
    path: "/funding_sources",
  },
  EmployerLevel: {
    path: "/employer_levels",
  },
  EmployerType: {
    path: "/employer_types",
  },
  TargetGroup: {
    path: "/target_groups",
  },
  TargetLevel: {
    path: "/target_levels",
  },
  Organizer: {
    path: "/organizers",
  },
  Format: {
    path: "/formats",
  },
  EventCharacter: {
    path: "/event_characters",
  },
  Community: {
    path: "/communities",
  },
  GoodPractice: {
    path: "/good_practices",
  },
};

import ItemService from "./ItemService";
import {Endpoints, Settings} from "../config.js";
import {each} from "lodash";
import {I18n} from "../i18n";
import eventFormats from "../components/charts/registry/event-formats";


class EventService extends ItemService {
    constructor(onUpdateState, lang) {
        super(Endpoints.Event.path, onUpdateState, lang);

        this.idFirstSteps = 4;
    }

    async GetTrainingPackageTypes(restrictCTP, selected) {
        const data = await this.GetItems("training_package_types?size=1000");

        const ctpId = 2;
        const result = [];
        each(data, (tsp) => {
            if (tsp.id === ctpId && restrictCTP && !selected) {
                return;
            }
            result.push({
                ...tsp,
                value: tsp.id.toString(),
                label: this._lang === "Eng" ? tsp.nameEn : tsp.nameUa,
            });
        });

        return result;
    }

    async GetThematicModules() {
        const data = await this.GetItems("thematic_modules?size=1000");
        return data;
    }

    async GetEventCharacters(selected, i18n) {
        const data = await this.GetItems("event_characters?size=1000");

        data.unshift({
            id: "",
            en: i18n["Eng"].common.no,
            ua: i18n["Ukr"].common.no,
        });

        const result = data
            .filter((x) => x.active || x.id === selected)
            .map(this.GetValueAndLabel.bind(this));

        return result;
    }

    async GetEventOwners(fake) {
        const data = await this.GetItems(
            `users/event_owners${fake ? "?all=1" : ""}`
        );
        const result = data.map((item) => ({
            value: item.id,
            label: item.name,
            ...item,
        }));
        return result;
    }

    async GetTrainers() {
        const data = await this.GetItems("trainers?for_select=true&size=3000");
        const result = data.map((item) => ({
            value: item.id,
            label: `${item.name} ${item.cellPhone} ${item.nameTranscription}`,
            ...item,
        }));
        return result;
    }

    async GetFacilitators() {
        const data = await this.GetItems("facilitators?for_select=true&size=1000");
        const result = data.map((item) => ({
            value: item.id,
            label:
                this._lang === "Eng" ? item.nameTranscription || item.name : item.name,
            ...item,
        }));
        return result;
    }

    MapStateToModel(state) {
        let data = super.MapStateToModelMultiPart(state);

        const params = this.GetApiPutParams();

        for (let p of params) {
            if (p.name === "mediaLinks") {
                data.append(p.name, JSON.stringify(state.mediaLinks));
            } else if (p.name === "categoryIds") {
                this.MapArray(p.name, data, state.categories);
            } else if (p.name === "europeActionIds") {
                if (state.europeActions && state.europeActions !== 0) {
                    data.append(p.name, JSON.stringify([Number(state.europeActions)]));
                }
            } else if (p.name === "trainingPackageTypeIds") {
                if (
                    state.showThematicPackages &&
                    state.trainingPackageTypes &&
                    state.trainingPackageTypes !== 0
                ) {
                    data.append(
                        p.name,
                        JSON.stringify([Number(state.trainingPackageTypes)])
                    );
                } else {
                    data.append(p.name, JSON.stringify([]));
                }
            } else if (p.name === "thematicModuleIds") {
                if (
                    state.showThematicPackages &&
                    state.thematicModules &&
                    state.thematicModules !== 0
                ) {
                    data.append(p.name, JSON.stringify([Number(state.thematicModules)]));
                } else {
                    data.append(p.name, JSON.stringify([]));
                }
            } else if (p.name === "placeOfEventCoordinates" && state.position) {
                // api saves lat lng twisted
                data.append(p.name, `POINT(${state.position[0]} ${state.position[1]})`);
            } else {
                this.MapProperty(data, state, p);
            }
        }

        return data;
    }

    GetInitialState(userRoles, currentPhaseId) {
        let state = super.GetInitialState(userRoles, currentPhaseId);

        const asUstanova = userRoles.indexOf("ROLE_RO") >= 0;

        state = {
            ...state,
            showCategory: Settings.Tenant.IsUlead,
            showImplementedThroughTsp: Settings.Tenant.IsUlead,
            showOwner: userRoles.indexOf("ROLE_ANALYST") >= 0,
            asOES:
                userRoles.indexOf("ROLE_OES") >= 0 ||
                userRoles.indexOf("ROLE_MIREG") >= 0 ||
                userRoles.indexOf("ROLE_GUEST") >= 0,
            showRegionalInitiatives: !asUstanova && state.isFirstPhase,
            restrictCTP: asUstanova,

            subCategories: [],
            thematicModulesClustered: [],
            mediaLinks: [{url: "", title: ""}],
            showThematicPackages: null,
            showThematicModules: false,
            oblast: "",
            eventFormat: "",
            asCreated: false,
            startDate: null,
            endDate: null,
            showEventCharacters: true,
            showActualNumberOfParticipants: this.showActualNumberOfParticipants(
                state
            ),
            showExpectedNumberOfParticipants: this.showExpectedNumberOfParticipants(
                state
            ),

            eventFormats: [],
            eventFormatsRaw: [],
            users: [],

            initialPosition: [50.4501, 30.5234],

            zoom: 12,

            isStartUp: false,


        };

        return state;
    }

    UpdateStateFromItem(state, item) {
        state = super.UpdateStateFromItem(state, item);

        // perform more complex state operations
        state = this.SetEndDate(state, item.endDate);
        state = this.SetEventFormat(state, item.formatId);
        state = this.SetPlaceOfEventCoordinates(state, {
            latLng: item.placeOfEventCoordinates?.coordinates,
        });

        if (!state.isTransient) {
            state = this.SetStartDate(state, item.startDate);
            state = this.SetMediaLinks(state, item.mediaLinks);
        } else {
            state = {...state, startDate: null, endDate: null};
        }

        //state = this.SetEventCharacters(state, item.eventCharacters);
        state = this.SetCategories(state, item.categories);
        state = this.SetEuropeActions(state, item.europeActions);

        // if thematic modules come as array, we convert it to single value here
        if (item.thematicModules && Array.isArray(item.thematicModules)) {
            let thematicModules =
                item.thematicModules.length > 0 ? item.thematicModules[0].id : 0;
            state = {...state, thematicModules};
        }

        // if trainingPackageTypes come as array, we convert it to single value here
        if (item.trainingPackageTypes && Array.isArray(item.trainingPackageTypes)) {
            let trainingPackageTypes =
                item.trainingPackageTypes.length > 0 ? item.trainingPackageTypes[0].id : 0;
            state = {...state, trainingPackageTypes};
        }

        let showThematicPackages = !item.id ? null :
            !!(
            (state.thematicModules && state.thematicModules > 0) ||
            (state.trainingPackageTypes && state.trainingPackageTypes > 0)
        );

        state = {...state, showThematicPackages};

        return state;
    }

    NavigateAfterSave(state, response) {
        if (state.isTransient) {
            return super.NavigateAfterSave(state, response);
        }

        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }
        state.messages.push(this.GetRedirect(`/events/view/${state.id}`));

        return state;
    }

    async Action(e, state) {
        // base class is not called and contains only default implementation
        // could be improved ...

        switch (e.fieldName) {
            case "startDate":
                state = this.SetStartDate(state, e.value);
                break;
            case "endDate":
                state = this.SetEndDate(state, e.value);
                break;
            case "formatId":
                state = this.SetEventFormat(state, e.value);
                break;
            case "categories":
                state = this.SetCategories(state, e.value);
                break;
            // case "trainers":
            //   state = this.SetTrainers(state, e.value);
            //   break;
            // case "eventCharacters":
            //   state = this.SetEventCharacters(state, e.value)
            //   break;
            case "showThematicPackages":
                state = this.SetShowThematicPackages(state, e.value);
                break;
            case "trainingPackageTypes":
                state = this.SetTrainingPackageTypes(state, e.value);
                break;
            case "europeActions":
                state = this.SetEuropeActions(state, e.value);
                break;
            case "mediaReference_Add":
                state = this.AddMediaReference(state);
                break;
            case "mediaReference_Remove":
                state = this.RemoveMediaReference(state, e.value);
                break;
            case "mediaReference_ChangeTitle":
                state = this.MediaReferenceChangeTitle(state, e.value);
                break;
            case "mediaReference_ChangeUrl":
                state = this.MediaReferenceChangeUrl(state, e.value);
                break;
            case "mapSearch":
                state = this.SetPlaceOfEventCoordinates(state, e.value);
                break;

            default:
                // never modify state, always clone
                state = {...state};
                state[e.fieldName] = e.value;
                break;
        }

        state = this.SetStateChanged(state);

        console.log("FieldChanged", {event: e, newState: state});

        return state;
    }

    // api defines parameters in snake-case but actually expects camel case, this is from swagger-but cvonverted to camel-case
    GetApiPutParams() {
        return [
            {
                in: "formData",
                name: "nameEn",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "nameUa",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "descEn",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "descUa",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "startDate",
                type: "string",
                format: "date",
                required: false,
            },
            {
                in: "formData",
                name: "endDate",
                type: "string",
                format: "date",
                required: false,
            },
            {
                in: "formData",
                name: "partOfSeries",
                type: "boolean",
                required: false,
            },
            {
                in: "formData",
                name: "eventReport",
                type: "file",
                required: false,
            },
            {
                in: "formData",
                name: "eventFoto",
                type: "file",
                required: false,
            },
            {
                in: "formData",
                name: "internalFeedback",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "mediaReferences",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "ownerId",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "regionId",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "formatId",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "organizerIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "targetGroupIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "categoryIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "targetLevelIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "trainerIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "facilitatorIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "fundingSourceIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "eventCharacterIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            // {
            //   "in": "formData",
            //   "name": "survey",
            //   "type": "string",
            //   "required": false
            // },
            {
                in: "formData",
                name: "eventAddress",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "expectedNumberOfParticipants",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "actualNumberOfParticipants",
                type: "integer",
                format: "int32",
                required: false,
            },
            {
                in: "formData",
                name: "placeOfEvent",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "placeOfEventCoordinates",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "marked",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "implementedBy3RdParty",
                type: "boolean",
                required: false,
            },
            {
                in: "formData",
                name: "nameOfImplementingPartner",
                type: "string",
                required: false,
            },
            {
                in: "formData",
                name: "enableCreateSurvey",
                type: "boolean",
                required: false,
            },
            {
                in: "formData",
                name: "europeActionIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "mediaLinks",
                type: "array",
                items: {
                    type: "json",
                },
                required: false,
            },
            {
                in: "formData",
                name: "trainingPackageTypeIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "thematicModuleIds",
                type: "array",
                items: {
                    type: "integer",
                    format: "int32",
                },
                required: false,
            },
            {
                in: "formData",
                name: "responsiblePerson",
                type: "string",
                required: false,
            },
            {
                in: "path",
                name: "id",
                type: "integer",
                format: "int32",
                required: true,
            },
            {
                in: "formData",
                name: "kind",
                type: "string",
                required: false,
            }
        ];
    }

    SetEventFormat(state, value) {
        const payload = {
            showTrainers: (value === 3 || value === 6 || value === 7 || value === 10) && !state.asOES,
            showFacilitators: (value === 4 || value === 5 || value === 8 || value === 9) && !state.asOES,
            formatId: value,
        };

        let result = {
            ...state,
            ...payload,
        };

        result = {
            ...result,
            showActualNumberOfParticipants: this.showActualNumberOfParticipants(
                result
            ),
        };

        return result;
    }

    SetEndDate(state, value) {
        let result = {
            ...state,
            endDate: value,
        };

        result = {
            ...result,
            showExpectedNumberOfParticipants: this.showExpectedNumberOfParticipants(),
        };

        return result;
    }

    SetStartDate(state, value) {

        if(value) {
           const startDate = new Date(value);
            const today = new Date();

            let result = {
                ...state,
                startDate: startDate,
                showEventCharacters: state.isTransient || startDate > today,
                eventFormats: state.eventFormatsRaw.filter(f =>
                            (startDate < Settings.HideEmergencyConsultationsAfter || f.id != Settings.EmergencyConsultationId)
                            && (startDate < Settings.HideEmergencySupportAfter || f.id != Settings.EmergencySupportId)
                    ),
                showNetworkTitle: startDate > Settings.HideTSPsAfter
            };

            result = {
                ...result,
                showActualNumberOfParticipants: this.showActualNumberOfParticipants(
                    result
                ),
            };
            return result;
        }
        else
        {
            // if start date is undefined
            // return full list of eventFormats
            return {...state, eventFormats: state.eventFormatsRaw}
        }
    }

    SetPlaceOfEventCoordinates(state, value) {
        let position, coordString;

        if (value && value?.latLng?.length === 2) {
            // lat and long may be in different order in DB
            let lat =
                value.latLng[0] > value.latLng[1] ? value.latLng[0] : value.latLng[1];
            let long =
                value.latLng[0] > value.latLng[1] ? value.latLng[1] : value.latLng[0];

            position = [lat, long];

            coordString = `${lat}|${long}`;
        } else {
            position = state.initialPosition;
            coordString = "";
        }

        let result = {
            ...state,
            position,
            coordString,
        };
        if (
            (!result.eventAddress || result.eventAddress === "") &&
            value?.address && value.address.query
        ) {
            result = {...result, eventAddress: value.address.query};
        }

        return result;
    }

    SetEuropeActions(state, value) {
        // transform array to single value
        let singleValue = value;
        if (value && Array.isArray(value)) {
            singleValue = value.length > 0 ? value[0].id : 0;
        }

        const result = {...state, europeActions: singleValue};
        return result;
    }

    SetCategories(state, value) {
        if (value) {
            if (value.length > 2) {
                value.pop();
            }

            let subCategories = [];

            each(value, (category) => {
                let title = `Subcategories of ${
                    this._lang === "Eng" ? category.en : category.ua
                }`;

                const description =
                    this._lang === "Eng" ? category.descEn : category.descUa;
                const list = description ? description.split("|") : [];
                subCategories.push({title: title, list: list});
            });

            const result = {
                ...state,
                subCategories: subCategories,
                categories: value,
            };
            return result;
        } else {
            return state;
        }
    }

    SetMediaLinks(state, value) {
        const result = {...state, mediaLinks: value ?? []};
        return result;
    }

    // SetEventCharacters(state, value) {
    //   let newSelectedOptions = [];

    //   const hasPreviousWithoutValue = filter(state.eventCharacters, option => !option.value).length > 0;
    //   const nextWithoutValue = filter(value, option => !option.value);

    //   const nextHasMoreValues = value?.length > nextWithoutValue.length;

    //   if (nextHasMoreValues && hasPreviousWithoutValue) {
    //     newSelectedOptions = filter(value, option => option.value);
    //   } else if (!hasPreviousWithoutValue && nextWithoutValue.length) {
    //     newSelectedOptions = nextWithoutValue;
    //   }

    //   const result = { ...state, eventCharacters: newSelectedOptions };
    //   return result;
    // }

    SetShowThematicPackages(state, value) {
        let result = {...state, showThematicPackages: value};

        if (!value) {
            result = {...result, trainingPackageTypes: null, thematicModules: null};
        }

        return result;
    }

    //impure
    SetTrainingPackageTypes(state, value) {

        // if tpt come as array, we convert it to single value here
        if (value && Array.isArray(value)) {
            value = value.length > 0 ? value[0].id : 0;
        }

        let newState = {trainingPackageTypes: parseInt(value)};

        if (newState.selectedTrainingPackageType === "1") {
            const thematicModulesClustered = [];

            for (let item of state.thematicModulesList) {
                if (item.trainingPackageTypeId === 1) {
                    thematicModulesClustered.push({
                        value: item.id.toString(),
                        label: this._lang === "Eng" ? item.nameEn : item.nameUa,
                        cluster: item.cluster,
                    });
                }
            }

            newState = {
                ...newState,
                thematicModulesClustered,
                isStartUp: true,
            };
        } else {
            //const thematicModules = state.thematicModulesList;
            const thematicModulesListBuf = [];
            const thematicModulesClustered = [
                {label: I18n[this._lang].events.clusterA, options: []},
                {label: I18n[this._lang].events.clusterB, options: []},
                {label: I18n[this._lang].events.clusterC, options: []},
            ];
            each(state.thematicModulesList, (item) => {
                thematicModulesListBuf.push({
                    value: item.id.toString(),
                    label: this._lang === "Eng" ? item.nameEn : item.nameUa,
                    cluster: item.cluster,
                });
            });
            each(thematicModulesListBuf, (item, index) => {
                if (item.cluster === "a") {
                    thematicModulesClustered[0].options.push(item);
                }
                if (item.cluster === "b") {
                    thematicModulesClustered[1].options.push(item);
                }
                if (item.cluster === "c") {
                    thematicModulesClustered[2].options.push(item);
                }
            });

            newState = {
                ...newState,
                thematicModulesClustered,
                isStartUp: false,
                // showThematicPackages: !!(
                //   (state.trainingPackageTypesList &&
                //     state.trainingPackageTypesList.length) ||
                //   (state.thematicModulesList && state.thematicModulesList.length)
                // ),
            };
        }

        const ctpId = 2;
        const selectedCTP = state.trainingPackageTypesList?.find(
            (x) => x.id === ctpId
        );


        let showThematicModules = (state.startDate &&  state.startDate <= Settings.HideTSPsAfter)
                                        && (state.currentPhaseId === 1 || newState.trainingPackageTypes !== this.idFirstSteps);

        const result = {
            ...state,
            ...newState,
            isDisabledTSP: state.restrictCTP && selectedCTP,
            showThematicModules,
        };

        console.log("SetTrainingPackageTypes", result)
        return result;
    }

    AddMediaReference(state) {
        const mediaLinks = state.mediaLinks.concat([{url: "", title: ""}]);

        const result = {...state, mediaLinks};
        return result;
    }

    RemoveMediaReference(state, item) {
        const mediaLinks = state.mediaLinks.filter((s, sidx) => item.idx !== sidx);

        const result = {...state, mediaLinks};
        return result;
    }

    MediaReferenceChangeUrl(state, item) {
        const mediaLinks = state.mediaLinks.map((mediaReference, sidx) => {
            if (item.idx !== sidx) return mediaReference;
            return {...mediaReference, url: item.url};
        });

        const result = {...state, mediaLinks};
        return result;
    }

    MediaReferenceChangeTitle(state, item) {
        const mediaLinks = state.mediaLinks.map((mediaReference, sidx) => {
            if (item.idx !== sidx) return mediaReference;
            return {...mediaReference, title: item.title};
        });

        const result = {...state, mediaLinks};
        return result;
    }

    ValidateItem(state) {
        let result = super.ValidateItem(state);

        this.validationRequired(result, state, "nameEn");
        this.validationRequired(result, state, "nameUa");
        this.validationRequired(result, state, "startDate");
        this.validationRequired(result, state, "endDate");
        this.validationRequired(result, state, "formatId");
        this.validationSelectionRequired(result, state, "regionId");

        if (state.showThematicPackages === true) {
            this.validationSelectionRequired(result, state, "trainingPackageTypes");
            if (state.showThematicModules === true) {
                this.validationSelectionRequired(result, state, "thematicModules");
            }
        }

        const maxFileSize = 10485760; // 10mb
        this.validationFileSize(result, state, "eventFoto", maxFileSize);
        this.validationFileSize(result, state, "eventReport", maxFileSize);

        if(state.showCategory &&
            Settings.ConsultationMode && state.startDate >=new Date(2022, 2, 24))
        {
            this.validationNotEmpty(result, state, "categories");
            this.validationRequired(result, state, "kind");
        }

        // js Month is Zero-based: this is "17th November 2023"
        if(state.startDate >=new Date(2023, 10, 17))
        {
            this.validationNotEmpty(result, state, "organizers");
            this.validationRequired(result, state, "responsiblePerson");

            if(state.showImplementedThroughTsp)
            {
                this.validationSelectionRequired(result, state, "showThematicPackages");
            }
        }

        if (!Settings.ConsultationMode) {
            this.validationRequired(result, state, "descEn");
            this.validationRequired(result, state, "descUa");
            this.validationRequired(result, state, "nameUa");

            this.validationRequired(result, state, "responsiblePerson");

            if (state.startDate && state.endDate) {
                if (state.startDate > state.endDate) {
                    result.startDate = "Start date must be before end date";
                }
            }

            // react-select do not render input for isDisabled component
            this.validationRequired(result, state, "showThematicPackages");

            if (state.showOwner) {
                this.validationSelectionRequired(result, state, "ownerId");
            }
            this.validationNotEmpty(result, state, "categories");
            this.validationNotEmpty(result, state, "targetGroups");
            this.validationNotEmpty(result, state, "targetLevels");
            this.validationNotEmpty(result, state, "fundingSources");
            this.validationRequired(result, state, "eventAddress");

            const today = new Date();
            if (state.endDate && state.endDate > today) {
                this.validationNumberRequired(
                    result,
                    state,
                    "expectedNumberOfParticipants"
                );
            }
        }

        return result;
    }

    showActualNumberOfParticipants(state) {
        //return state.formatId === 6 || state.formatId === 8;
        return true;
    }

    showExpectedNumberOfParticipants(state) {
        // const today = new Date();

        // const showExpectedNumberOfParticipants = !!;

        // const result = {
        //   ...state,
        //   endDate: value,
        //   showExpectedNumberOfParticipants: (endDate > today),
        // };
        return true;
    }

    MessageSaveSucceeded(state, r) {
        console.log("Save suceeded");
        state = {...state};

        if (!state.messages) {
            state.messages = [];
        }

        if (state.returnedItem.phaseId !== state.currentPhaseId) {
            state.messages.push(
                this.GetInfo(
                    "Event sucessfully saved and Event has been moved to another Phase!"
                )
            );
        } else {
            state.messages.push(this.GetInfo("Event sucessfully saved"));
        }

        state.messages.push({type: "triggerSaveSucceeded"});
        return state;
    }
}

export default EventService;
